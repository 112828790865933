<div class="content-popup">
  <button [mat-dialog-close]="true" class="d-flex button-close">
      <img class="img--close" src="assets/images/Close.svg" alt="">
  </button>
  <h2 class="title">{{data.title}}</h2>
  <div class="row justify-content-center mt-3">
    <div class="col-2">
      <img class="icons-modal" src="assets/images/mail-bulk.svg" alt="">
    </div>
    <div class="col-10 content-text">
      <span class="text">
          Si tenías cuenta en <span class="bold">otros portales de autogestión</span> y <span class="bold">no</span> tenés cuenta en Gestión Online, deberás registrarte.
      </span>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-2">
      <img class="icons-modal" src="assets/images/user-circle.svg" alt="">
    </div>
    <div class="col-10">
      <span class="text">
        {{ data.content2 }}
      </span>      
    </div>
  </div> 
</div>
