<div class="col-12 p-md-1 p-sm-2 p-xs-2">
  <div class="row py-4 paso_2 mx-1">
    <div class="col-12 mt-2 mb-5 text-center">
      <img src="assets/images/telecom.svg" class="mb-4" alt="" />
    </div>
    <div class="col-12 my-5">
      <h1 class="title text-center mt-4">Activación</h1>
    </div>
   
    <div class="container">
      <form class="col-12 mb-5 p-0">
        <div class="form-group">
          <h6>Nombre de usuario: <strong>{{ name }}</strong></h6>
        </div>
        <div class="form-group">
          <h6>Apellido de usuario: <strong>{{ lastName }}</strong></h6>
        </div>
        <div class="form-group mb-5">
          <h6>Email: <strong>{{ email }}</strong></h6>
        </div>
        <div class="row align-items-center" *ngIf="this.activatedUser">
          <div class="col-12 mt-4 mb-5">
            <b2b-button data-ui-element="ACTIVAR USUARIO" variant="primary" class="btn login-btn w-100" text="ACTIVAR USUARIO" (click)="this.activate()"></b2b-button>
          </div>
          <br>
        </div>

        <div class="row align-items-center" *ngIf="!this.activatedUser">
          <div class="col-12 mt-3 mb-2 form-group text-center">
            <h5>
              No se pudo realizar la activación. Intentelo de nuevo más tarde.
            </h5>
          </div>
          <div class="col-12 mt-4 mb-5">
            <button data-ui-element="Ir a inicio" (click)="goHome()" class="btn btn-no-acepto w-100">Ir a inicio</button>
          </div>
          <br>
        </div>
      </form>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>

</div>
