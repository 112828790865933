import { Component, forwardRef, Input } from "@angular/core";
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { Variants } from "./input.model";
import {
  faEye,
  faCircleQuestion,
  faEyeSlash,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons";

export type InputDarkModeType = "text" | "number" | "password" | "email";


@Component({
  selector: "app-input-dark-mode",
  templateUrl: "./input-dark-mode.component.html",
  styleUrls: ["./input-dark-mode.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDarkModeComponent),
      multi: true,
    },
  ],
})
export class InputDarkModeComponent implements ControlValueAccessor {
  @Input() label?: string;
  @Input() tooltip?: string;
  @Input() placeholder?: string;
  @Input() password?: boolean = false;
  @Input() required: boolean = true;
  @Input() isDisabled?: boolean;
  @Input() errorMessage?: string;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() variant: Variants;
  @Input() type: InputDarkModeType = "text";
  @Input() area?: string;

  hide: boolean = true;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faCircleQuestion = faCircleQuestion;

  inputValue: string = "";
  onChange: (value: string | number) => void = () => {};
  onTouched: () => void = () => {};
  showErrorMsg: boolean = false;

  constructor() {}

  ngOnInit() {
    console.log("Valor de isDisabled:", this.isDisabled);
  }

  writeValue(value: any): void {
    this.inputValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onInputChange(value: string): void {
    this.inputValue = value;
    this.onChange(value);
    this.onTouched();
  }

  toggleVisibility(): void {
    this.hide = !this.hide;
    this.type = this.hide ? "password" : "text";
  }
}
