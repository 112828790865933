<div class="row">
  <div class="col-12 p-md-1 p-sm-2 p-xs-2">
      <div class="col-12 mt-3 text-center logo-mobile">
        <img class="my-3" src="assets/images/telecom.svg" alt="" />
      </div>
    <div class="row  mx-1">
      <div class="mx-auto mt-4 mobile">
        <div class="login-content">
          <div class="container">



        <form class="login-form ">
          <h1>Ingresá a tu <strong>cuenta empresa</strong></h1>

          <div class="w-100 login-toast" *ngIf="showToast">
            <div class=" m-md-3 mt-0">
              <div class="row bar">
                <div [ngClass]="toastType == 'warning' ? 'toast-warn toast-icon' : 'toast-error toast-icon'" >
                  <img class="ico" [src]="toastType == 'warning' ? 'assets/images/warning.svg' : 'assets/images/ico-info.svg'" alt="img-Info">
                </div>
                <div class="col disclaimer">
                  <p class="toast-title" *ngIf="toastTitle">{{this.toastTitle}}</p>
                  <p class="mb-0">{{this.toastMessage}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group mb-4">
            <input
              type="text"
              class="form-control pl-3 pt-4 mx-auto"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              [formControl]="loginForm.controls.username"
              autocomplete="off" [readonly]="isReadOnly" (focus)="removeReadOnly()"
              required
            />
            <label [ngClass]="toastError ? 'error-label' : ''">Tu email</label>
            <span class="icon-circle-info" (click)="popupMessage()" data-ui-element="¿Cuál es mi usuario?">
              <img  src="assets/images/info-circle.svg" alt="" />
            </span>
          </div>

          <div class="form-group mt-2">
            <div class="col-xs-10 input">
              <div class="password-input">
                <input
                  class="form-control pl-3 pt-4 mx-auto"
                  id="exampleInputPassword1"
                  [formControl]="loginForm.controls.password"
                  [type]="hide ? 'text' : 'password'"
                  autocomplete="off" [readonly]="isReadOnly" (focus)="removeReadOnly()"
                  required
                />
                <label [ngClass]="toastError ? 'error-label' : ''"> Tu contraseña</label>
                <span class="icon-eye-pass">
                  <fa-icon
                    [icon]="hide ? faEye : faEyeSlash"
                    (click)="hide = !hide"
                  ></fa-icon>
                </span>
              </div>
            </div>

            <input type="hidden" name="ReturnUrl" />
            <a
              data-ui-element="¿Olvidaste la contraseña?"
              (click)="resetPassword()"
              class="cursor-pointer text-left"
            >
              <small id="emailHelp" class="form-text mt-3 pl-3 mobile"
                >¿Olvidaste la contraseña?</small
              >
            </a>
          </div>

          <div class="col-12 d-flex justify-content-center mt-4 mb-3 mobile">
            <button
              data-ui-element="Ingresar"
              class="btn login-btn"
              (click)="onLogin()"
              [disabled]="loginForm.invalid || showSpinner"
              >
              <span *ngIf="!showSpinner">Ingresar</span>
              <div class="d-flex lista justify-content-center align-items-center" *ngIf="showSpinner">
                <btn-spinner class="spinner"></btn-spinner>
              </div>
            </button>
          </div>
        </form>


      <hr class="mt-4 log-in-hr" />

      <div class="col-12 d-flex justify-content-center register-wrapper mt-3">
        <small class="no-account">¿No tenés cuenta?
          <span>
          <a
          data-ui-element="Registrarme ahora"
          type="submit"
          class="cursor-pointer link"
          (click)="goToRegister()"
        >
          Registrate ahora
          </a>
          </span>
        </small>
      </div>
    </div>
    </div>

      </div>
    </div>
  </div>

</div>
